import React, { Component } from 'react'
import ReactTypingEffect from "../vendor/src/lib"

export default class JsonObject extends Component {
    render() {
        return (
            <div className="m-json__wrapper">
                <div className="m-json__const"><span>const</span> developer <span>=</span> {"{"}</div>
                <div className="m-json__data">
                    <p>name: <span>"Rob Caluza"</span></p>
                    <p>devType: <span>"Frontend"</span></p>
                    <p>location:<span className="m-json__bracket">{" {"}</span></p>
                    <div className="m-json__nested">
                        <p>primary: <span>"Chicagoland Area"</span></p>
                        <p>secondary: <span>"Remote"</span></p>
                    </div>
                    <span className="m-json__bracket">{"}"},</span>
                    <p>badass: <ReactTypingEffect className={"m-json__iffy"} text={["fals", "true"]} speed={100} typingDelay={1700} eraseDelay={2000} /></p>
                </div>
                <div className="m-json__closing">{"}"}</div>
            </div>
        )
    }
}
