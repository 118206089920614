import React, { Component } from 'react'
import HeadShot from '../atoms/HeadShot'
import { Link } from 'gatsby'


export default class AboutPreview extends Component {
  render() {
    return (
      <section className="o-about-me-preview">
        <h3 className="a-section-header">About Me</h3>
        <HeadShot />
        <div className="m-quotebox">
          <span className="m-quotebox__quote">“You have no idea how high I can fly”</span>
          <span className='m-quotebox__author'>~ Michael Gary Scott</span>
        </div>
        <div className="a-description">
          <p>Hi, my name is <strong>Robert Caluza</strong>. I'm developer in the Greater Chicago Land Area.</p>
          <p>I'm passionate about making websites, I'm constantly working on developing my skills not just in Web-Dev but on creating real connections with people and clients!</p>
          <p>If you need a passionate Dev on your next project please...</p>
        </div>
        <Link to="/contact" className="a-link a-link--contact">Holler!</Link>
      </section>
    )
  }
}
