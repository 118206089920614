// import React, { useEffect } from "react"
import React from 'react'
import { Link, graphql } from 'gatsby'

import Container from "../organisms/PageContainer"
import SEO from "../atoms/seo"
import HeadImage from '../atoms/HeadImage'
// import PostPreview from "../molecules/PostPreview"
import JsonObject from "../molecules/JsonObject"
import AboutPreview from '../organisms/AboutPreview'
import Technology from '../molecules/Technologies'
// import Portfolio from '../molecules/Portfolio'


const IndexPage = ({ data }) => {
  // const latestPosts = data.allContentfulBlogPost.edges;
  // useEffect(() => {
  //   const nav = document.querySelector('.m-nav');
  //   // const blogPreview = document.querySelector('.m-blog-preview__wrapper');
  //   const blogPreview = document.querySelector('.o-blog-preview h3');
  //   const meImage = document.querySelector('.a-head-image');
  //   // const blogPreview = document.querySelector('.m-post-preview:nth-child(2)');
  //   const obCallback = (payload) => {
  //     console.log(payload);
  //     if (payload[0].intersectionRatio > 0) {
  //       console.log('butt butt butt butt yeasssssss');
  //       nav.classList.remove('m-nav--home');
  //       meImage.style.opacity = 1;
  //     }
  //   }
  //   const ob = new IntersectionObserver(obCallback);
  //   ob.observe(blogPreview);
  // })


  return (
    <Container id="home">
        <SEO title="Home" />
        <div className="m-hero m-hero--home ">
            <div className="m-hero__info">
                <HeadImage />
                <h1><span>Rob Caluza</span><br/ >Frontend Software Engineer</h1>
            </div>
            <JsonObject />
            <Link to="/contact" className="a-link">Reach out <span>⮕</span></Link>
        </div>
        {/* <section className="o-blog-preview">
            <h3>Latest Blog Posts</h3>
            <p className="a-description">Here's the latest that's on my mind!</p>
            <ul className="m-blog-preview__wrapper">
                {latestPosts.map(post => (
                    <Link
                      to={`/blogpost/` + post.node.slug}
                    >
                        <PostPreview
                          key={post.node.id}
                          authorName={post.node.author.name}
                          description={post.node.description.description}
                          heroImage={post.node.heroImage.fluid.src}
                        />
                    </Link>
                ))}
            </ul>
        </section> */}
        <AboutPreview />
        <Technology />
        {/* <Portfolio /> */}
    </Container>
  )
}

export const data = graphql`
  query posts {
        allContentfulBlogPost(limit: 3) {
        edges {
      node {
        id
          slug
      author {
        name
      }
      description {
        description
      }
      heroImage {
        file {
      url
    }
            fluid {
        src
      }
      }
    }
  }
}
}
`

export default IndexPage;