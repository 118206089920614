import React, { Component } from 'react'
import { Link } from 'gatsby';

const yearsofExperience = () => {
    const startDate = new Date('2016-01-01');
    const today = new Date();
    const years = today.getFullYear() - startDate.getFullYear();
    return years;
}
export default class Technologies extends Component {
    render() {
        return (
            <section className="m-technology">
                <div className="m-technology__header">
                    <h3 className="a-section-header">My Tool Belt</h3>
                    <div className="m-quotebox">
                        <span className="m-quotebox__quote">“I rarely keep up with Kardashians, only because I try to keep up with all the changing technologies in Web Dev.”</span>
                        <span className="m-quotebox__author">~ ME (Rob Caluza)</span>
                    </div>
                    <div className="a-description">
                        <p>I've been having a blast developing sites for the last {yearsofExperience()} years. I have learned a lot and still continue learning and building upon my skill-set.</p>
                    </div>
                </div>
                <div className="m-technology__list__wrapper">
                    <div className="m-technology__list">
                        <h3>Services I offer</h3>
                        <ul>
                            <li className="m-technology__technology">Custom Site Development</li>
                            <li className="m-technology__technology">WordPress Training/Updates/Sites</li>
                            <li className="m-technology__technology">React Websites/WebApps</li>
                            <li className="m-technology__technology">Webflow Websites</li>
                            <li className="m-technology__technology">Consulting</li>
                        </ul>
                    </div>
                    <div className="m-technology__list">
                        <h3>My Developer Love Languages</h3>
                        <ul>
                            <li className='m-technology__technology'>HTML/CSS/JavaScript</li>
                            <li className='m-technology__technology'>React</li>
                            <li className='m-technology__technology'>Next.js</li>
                            <li className='m-technology__technology'>Gatsby</li>
                            <li className='m-technology__technology'>PHP/WordPress</li>
                        </ul>
                    </div>
                    <div className="m-technology__list">
                        <h3>What I'm currently learning</h3>
                        <ul>
                            <li className="m-technology__technology">Next.JS</li>
                            <li className="m-technology__technology">Accessibility</li>
                            <li className="m-technology__technology">Sanity.IO</li>
                            <li className="m-technology__technology">HTMX</li>
                        </ul>
                    </div>
                </div>
                <div className="a-description">
                    <p>Paired with my thirst for having a deep understanding of the technologies I have under my tool belt, I'm always excited about learning and applying new things.</p>
                </div>
                <h3>Interested in how I can help you and/or your business?</h3>
                <Link to="/contact" className="a-link a-link--contact">Holler</Link>
            </section>
        )
    }
}
